const es = {
  loading: "Cargando",
  sinpe: "SINPE Móvil",
  iban: "Cuenta IBAN",
  interac: "Interac e-Transfer",
  crc: "Colones",
  usd: "Dolares",
  cad: "Dolares",
  btc: "Bitcoin",
  sats: "Sats",
  continue: "Continuar",
  cancel: "Cancelar",
  canceled: "Cancelado",
  save: "Guardar",
  edit: "Actualizar",
  refresh: "Actualizar",
  previous: "Anterior",
  next: "Próxima",
  delete: "Eliminar",
  close: "Cerrar",
  IN_PENDING: "Ingreso Pendiente",
  IN_IN_PROGRESS: "Ingreso en Progreso",
  IN_COMPLETED: "Ingreso Completado",
  OUT_PENDING: "Salida Pendiente",
  OUT_IN_PROGRESS: "Salida en Progreso",
  OUT_COMPLETED: "Salida Completado",
  DONE: "Completado",
  NOT_READY: "No Listo",
  lightning: "Lightning",
  onchain: "Bitcoin (almacenamiento frío)",
  systemAlertTitle: "Noticia Importante",
  my: "Mis",
  new: "Nuevo",
  view: "Ver",
  rejected: "Rechazada",
  approved: "Aprobada",
  processing: "Procesando",
  send: "Enviar",
  balanceUsd: "Saldo Dolares",
  balanceCrc: "Saldo Colones",
  perDay: "por 24 horas",
  createVoucher: "Crear Voucher",

  navigation: {
    home: "Inicio",
    recipients: "Destinatarios",
    orders: "Ordenes",
    pos: "Punto de Venta",
    manage: "Actualizar Cuenta",
    logout: "Salir",
  },

  login: {
    welcome: "Bienvenidos a Bull Bitcoin",

    newUserHeader: "Clientes Nuevos",
    newUserText: "Si aún no tienes cuenta con Bull Bitcoin, comience aquí.",
    newUserBtn: "Crear Cuenta",

    returningUserHeader: "Clientes Existentes",
    returningUserText: "Si has usado Bull Bitcoin antes, haga clic aquí para iniciar sesión.",
    returningUserBtn: "Ingresar",

    systemMaintenance: "El sistema está en mantenimiento. ¡Vuelva pronto!",

    bitcoinOnly: "Porque no hay segundo mejor. ¡Sé indómito!",
    bitcoinOnlyTitle: "Solo Bitcoin",
    lightningFast: "Compra y vende Bitcoin en segundos. Onchain y Lightning.",
    lightningFastTitle: "Rápido como rayo",
    selfCustodial: "Bull Bitcoin no toma custodia de fondos. Mantenga control de tus fondos.",
    selfCustodialTitle: "Autocustodia",

    error: "Ha ocurrido un error durante el inicio de sesión",
    mfaError: "Cuentas con MFA no pueden crear Puntos de Venta en este momento. Póngase en contacto con el soporte.",
  
    fromAppHelper: "Comprar/Vender en Bitcoin Jungle es ofrecido por un tercero confiable. Bull Bitcoin es un intercambio de Bitcoin establecido y no está afiliado con Bitcoin Jungle.",

  },

  home: {
    buyBtnTitle: "Comprar Bitcoin",
    buyBtnSubtitle: "Reciba bitcoin en su billetera",

    sellBtnTitle: "Vender Bitcoin",
    sellBtnSubtitle: "Envia un pago por SINPE",

    contactBtnTitle: "Contactar Soporte",
    contactBtnSubtitle: "Póngase en contacto si tiene alguna pregunta",

    priceBtnTitle: "Ver Precios",
    priceBtnSubtitle: "Revisa la tasa actual",
  },

  recipients: {
    title: "Destinatarios",
    subtitle: "Una lista de todos tus destinatarios.",

    selectTitle: "Seleccione destinatario existente",
    selectSubtitle: "Seleccione el destinatario al que desea enviar.",

    searchPlaceholder: "Buscar destinatarios...",
    resetSearch: "Borrar búsqueda",

    createBtn: "Crear Destinatario",
    sendBtn: "Enviar Pago",

    none: "No se encontraron destinatarios.",

    select: "Seleccionar",
  },

  createrecipient: {
    title: "Crear Destinatario",
    subtitle: "Agregue un destinatario nuevo a su cuenta.",

    editTitle: "Actualizar Destinatario",
    editSubtitle: "Haga cambios en un destinatario existente en su cuenta.",

    nameTitle: "Contacto Favorito (opcional)",
    namePlaceholder: "Carnicería",

    destinationTypeTitle: "Tipo de Destino",

    phoneNumberTitle: "Número de Teléfono",

    delete: "¿Está seguro de que desea eliminar este destinatario?",

    "invalid-sinpe": "Este número de teléfono no está suscrito a SINPE Movil.",
    "uknown-iban": "No se encuentra esta cuenta IBAN.",

    errors: {
      destination: "Se requiere un destino.",
      invalidCurrencyCreate: "No puede crear un destinatario de este tipo de moneda.",
      invalidSinpe: "El destino no es un número de teléfono válido.",
      invalidIban: "El destino no es una cuenta IBAN válida.",
      invalidCurrency: "Moneda no válida para este orden.",
      invalidDestination: "No se puede localizar los detalles de esta cuenta.",
    },
  },

  orders: {
    title: "Ordenes",
    subtitle: "Una lista de todos los ordens en su cuenta.",

    createBtn: "Crear Orden",
    exportBtn: "Exportar a CSV",
    exporting: "Exportando...",
    exportError: "Error al exportar órdenes a CSV",
    exportSuccess: "Se exportaron {count} órdenes a CSV exitosamente",
    exportStarting: "Iniciando exportación...",
    generatingCsv: "Generando archivo CSV...",
    noOrdersToExport: "No hay órdenes para exportar",

    date: "Fecha",
    orderNumber: "Número de Orden",
    type: "Tipo",
    source: "Origen",
    status: "Estado",
    amount: "Monto",

    none: "No se encontraron ordens.",

    viewBtn: "Detalles",

    sellExplainer: "Orden en progreso, esperando confirmación en la blockchain. Una vez confirmada la transacción, su orden será procesada automáticamente.",
  },

  settings: {
    title: "Configuración",
    subtitle: "Administre los detalles de su cuenta.",

    email: "Correo Electrónico",
    password: "Contraseña",
    name: "Nombre Completo",
    firstName: "Nombres",
    lastName: "Apellidos",
    phone: "Número de Teléfono",
    country: "País",
    userNumber: "Número de Usuario",
    identity: "Identidad",

    verifyBtn: "Verificar",
    updateBtn: "Actualizar",

    pendingBalance: "Pending Payments",
    referralCode: "Código de Referencia",

    limitedVerification: "Puede enviar pagos SINPE e IBAN, hasta $3000. No puede comprar Bitcoin. Para acceder a todos los servicios sin límites, verifique su identidad.",
    sinpeVerified: "Puede enviar pagos SINPE e IBAN, y comprar Bitcoin, hasta $3000. Para acceder a todos los servicios sin límites, verifique su identidad.",
  },

  onboarding: {
    step: "Paso",

    step1Title: "Verificación Correo",
    step2Title: "Verificación Teléfono",
    step3Title: "Verificación Nombre",
  },

  verifyphone: {
    title: "Verifica número de teléfono",
    subtitle1: "Ingrese su número de teléfono para recibir un código.",
    subtitle2: "Ingrese el código que se acaba de enviar a su teléfono.",
    rejected: "No se acepta número de teléfonos de este pais.",

    code: "Código de Verificación",
    resendBtn: "Reenviar Código",
    verifyBtn: "Verifica Número",
  },

  verifyemail: {
    title: "Verifica Correo Electrónico",
    subtitle: "Verifique {email} y haga clic en el enlace para verificar su dirección.",
    resendBtn: "Reenviar Correo",
    confirmBtn: "OK, Listo",
  },

  verifyname: {
    title: "Verifica Nombre",
    subtitle: "Agregue su nombre completo a su perfil.",
    confirmBtn: "Verifica Nombre",
  },

  verifyidentity: {
    title: "Verificar Identidad",
    subtitle: "Por favor, verifique su identidad para aumentar sus límites.",
    text: "Por favor, haga clic en el botón de abajo para comenzar. Necesitará un dispositivo móvil con cámara y su identificación.",
    confirmBtn: "Comenzar Ahora",

    noAmericans: "No podemos servir a personas de los Estados Unidos. Si es residente de los Estados Unidos, no continúe con la verificación de identidad. Póngase en contacto con el soporte si tiene alguna pregunta.",
    notAmerican: "No soy residente de los Estados Unidos",
    notAllowedToTry: "Por favor, complete primero la verificación de su correo electrónico, teléfono y nombre.",
  },

  createorderprogress: {
    amount: "Monto",
    recipient: "Destinatario",
    message: "Mensaje",
    confirm: "Confirmar",
    deposit: "Depósito",
    method: "Método",
    rate: "1 BTC",

    IN_CRC_RDV_SINPE: "SINPE",
    IN_CRC_RDV_IBAN: "IBAN",
    IN_USD_RDV_IBAN: "IBAN",

    OUT_LN_false: "Lightning",
    OUT_LN_true: "Bitcoin Jungle",
    OUT_LNURL_PAY_true: "Bitcoin Jungle",
    OUT_LNURL_PAY_false: "Dirección Lightning",
    OUT_BITCOIN_false: "Bitcoin (almacenamiento frío)",
    OUT_BITCOIN_true: "Bitcoin (almacenamiento frío)",
  },

  amount: {
    title: "Monto",
    subtitle: "Ingrese la cantidad que desea vender.",
    buySubtitle: "Ingrese la cantidad que desea comprar.",

    satsBtn: "Vender todo",
    satsBtnConfirm: "¿Estás seguro de que deseas vender todos tus sats?",

    errors: {
      amount: "Se requiere un monto.",
      invalidAmount: "Monto invalido.",
      currency: "Se require una moneda.",
      invalidCurrency: "Moneda no válida para este destinatario.",
      disabledCurrency: "Esta moneda está deshabilitada temporalmente. Pruebe otra moneda o regrese más tarde.",
      minimumAmount: "El monto mínimo es {currencyIcon}{amount}",
    },

  },

  message: {
    title: "Mensaje",
    subtitle: "Ingrese un mensaje opcional para incluir con su pago.",
    placeholder: "Frutas y Verduras",
  },

  confirmsell: {
    title: "Confirmar Detalles",
    subtitle: "Revise y confirme los detalles de su orden.",
    confirmBtn: "Envia Pago",
    includesFees: "Tarifa Total",

    errors: {
      overBalance: "Esta cantidad de pedido excede el saldo de su billetera.",
    },
  },

  confirmbuy: {
    confirmBtn: "Crear Orden",

    bolt11Prompt: "Proporcione una factura lightning para {satAmount} sats.",
    bolt11AmountError: "La factura lightning proporcionada fue por la cantidad incorrecta. Inténtalo de nuevo.",

    addressInput: "Dirección Destinatario",
    invalidAddress: "Dirección de Bitcoin inválido",
    invalidLightningAddress: "Dirección de Lightning inválido",
    invalidInvoice: "Factura Lightning inválida",

    myLightningAddress: "Mi Cartera Bitcoin Jungle",
    bolt11Input: "Factura Lightning",
    bolt11AmountHint: "El monto de la factura debe ser exactamente",
    bolt11AmountError: "El monto de la factura no coincide con el monto del pedido",
  },

  processorder: {
    title: "Procesando su orden",
    subtitle: "Estamos procesando su orden, espere.",

    successTitle: "Orden completado con éxito!",
    orderNumber: "Número de orden ",
    referenceNumber: "Número de comprobante ",

    paying: "Pagando la factura de lightning",

    error: "Hubo un error que realizaba su orden. Por favor, inténtelo de nuevo más tarde.",
    timeoutError: "Hemos recibido su orden y estamos trabajando para procesarla lo más rápido posible.",

    returnToWallet: "Volver a app",
  },

  rules: {
    checkingPermission: "Comprobación de permisos",
    noBuyPermission: "Solo puede comprar Bitcoin si su cuenta ha sido verificada. Póngase en contacto con soporte si tiene alguna pregunta.",
    noSellPermission: "Solo puede vender bitcoin después de verificar su cuenta. Póngase en contacto con soporte si tiene alguna pregunta.",
    dailyLimitHit: "Has superado tu límite diario. Estos límites se aplican por período de 24 horas.",
    noPermission: "Tu cuenta no tiene permiso para realizar esta acción. Póngase en contacto con el soporte si tiene alguna pregunta.",
    prohibitedCountry: "Hemos detectado que su dirección IP o su dirección de domicilio está en un país prohibido (incluyendo el Reino Unido o los Estados Unidos). Póngase en contacto con el soporte para obtener más ayuda.",
  },

  deposit: {
    title: "Depósito",
    subtitle: "Haga una transferencia bancaria al monto de {amount} de su banco al nuestro.",

    details: "Aquí están los detalles de nuestra cuenta.",
    cedula: "Nuestra Cédula Jurídica es 3-102-875766, Toro Pagos Limitada.",

    automatic: "Envia SINPE por mensaje",
    manual: "Envia SINPE manualmente",

    descriptionTitle: "Incluya esta descripción con su transferencia.",
    descriptionSubtitle: "No incluya ninguna otra descripción.",

    slowAllowed: "Los ordenes de Bitcoin (on-chain) admiten métodos de transferencia bancaria lentos (de 1 a 3 días), así como métodos de transferencia bancaria instantánea. Tenga en cuenta que si elige un método de transferencia bancaria lenta, su cotización se volverá a calcular en el momento en que recibamos la transferencia bancaria.",

    confirmTitle: "Confirme que haya completado su transferencia.",
    confirmSubtitle: "Si no realiza la transferencia inmediata ahora, su cotización puede cambiar.",
    confirmLabel: "He completado la transferencia bancaria.",

  },

  jurisdiction: {
    title: "Seleccionar País",
    subtitle: "Por favor seleccione el país desde donde está enviando.",
  },

  simpledeposit: {
    title: "Comprar Bitcoin",
    subtitleCR: "Cualquier cantidad que envíe al número SINPE de abajo será automáticamente convertida a Bitcoin y enviada a su billetera de Bitcoin.",
    subtitleCA: "Cualquier cantidad que envíe al correo electrónico de abajo será automáticamente convertida a Bitcoin y enviada a su billetera de Bitcoin.",
    info: "Si excede su límite de transacción diaria, la transacción fallará. El soporte al cliente tendrá que contactarlo, y tendrá que esperar hasta el día siguiente para intentarlo de nuevo.",
    checkingRecipient: "Verificando destinatario...",
    yourLimit: "Su Límite",
    sinpeNumber: "Número SINPE Móvil",
    sinpeName: "Nombre SINPE",
    sinpeCedula: "Cédula SINPE",
    destinationAddress: "Dirección de Destino",
    limitAmount: "colones por 24 horas",
    errorNoUsername: "No se encontró nombre de usuario. Por favor, inicie sesión de nuevo.",
    interacEmail: "Correo Electrónico Interac",
    transferCode: "Código de Transferencia",
  },

  sendsms: {
    title: "Elige tu banco",
    subtitle: "Seleccione el banco que enviará desde abajo.",
  },

  buytype: {
    title: "Métodos de Transferencias",
    subtitle: "Seleccione el método de transferencia que desee.",
  },

  orderdetails: {
    title: "Detalles de Orden",
    subtitle: "Use esta página para ver los detalles de un pedido específico.",
    receiptBtn: "Ver Comprobante",
    success: "Transferencia Exitosa",

    type: "Tipo",
    bitcoinAmount: "Monto Bitcoin",
    fiatAmount: "Monto Fiat",
    fiatCurrency: "Moneda Fiat",
    fiatAmountNoFiat: "Monto",
    transferCode: "Descripción de Transferencia",
    destinationAddress: "Dirección de destino",
    destinationHash: "Transacción Bitcoin",
    message: "Descripción",
    origin: "Origen",
    destination: "Destinatario",
    rate: "1 BTC",
    network: "Red",

    SELL: "Venta",
    BUY: "Compra",


  },

  invoice: {
    pay: "Paga Factura",
    copied: "La factura se ha copiado en el portapapeles. Abra su billetera y pague esta factura.",

  },

  onchainModal: {
    pay: "Pay Address",
    copied: "The address has been copied to the clipboard. Please open your wallet and send exactly {btcAmount} BTC to this address.",
  },

  rates: {
    title: "Ver Precios",
    subtitle: "Utilice esta página para ver la tasa de compra y venta actual.",
    buy: "Tasa de Compra",
    sell: "Tasa de Venta",
    index: "Tasa Indexada",
    explainer1: "Bull Bitcoin calcula la Tasa de Compra de Bitcoin y la Tasa de Venta de Bitcoin para incluir la Tasa de Compra en USD y la Tasa de Venta en USD que obtiene de sus socios bancarios. Esto se debe al hecho de que no existe un mercado público donde Bitcoin se intercambie por Colones.",
    explainer2: "Las tasas cobradas por Bull Bitcoin se establecen al mínimo posible para garantizar no perder dinero al proporcionar el intercambio. Estamos comprometidos a mantener las tarifas más bajas posibles para beneficiar a los usuarios.",
    explainer3: "Bitcoin Jungle es una organización independiente que calcula sus propias tasas de cambio de Bitcoin. Como resultado, las tasas utilizadas por Bull Bitcoin y Bitcoin Jungle serán diferentes.",
    readMore: "Leer Más",
  },

  support: {
    title: "Soporte",
    subtitle: "¡Ponte en contacto con nosotros si tienes alguna pregunta!",

    chatTitle: "Chat con Soporte",
    chatSubtitle: "Puedes chatear con nosotros directamente aquí.",

    whatsapp: "WhatsApp",
    faq: "Preguntas Frecuentes",
    email: "Correo Electrónico",
    twitter: "Twitter",

    typeMessage: "Escribe un mensaje...",
    chatBtnTitle: "Chatear",
    contactBtnTitle: "Contactar",
    send: "Enviar",
    download: "Descargar",
  },

  loadingMessages: [
    "Recuerda tomar la autocustodia de tu bitcoin",
    "No confíes, verifica",
    "Estudia Bitcoin",
    "Bitcoin es una herramienta para liberar a la humanidad de oligarcas y tiranos",
    "Bitcoin es el avance monetario más significativo desde la creación de monedas",
    "Habrá una moneda nativa de Internet dentro de nuestra vida",
    "Canciller a punto del segundo rescate para los bancos",
    "Construimos soberanía",
    "La responsabilidad personal es el pilar de la sociedad",
    "Bitcoin- No hay segundo mejor",
  ],

  createpointofsaleprogress: {
    authenticate: "Autenticar",
    recipient: "Destinatario",
    percent: "Porcentaje",
    create: "Crear",
  },

  posform: {
    title: "Su tienda está lista",
    subtitle: "Ahora puede empezar a aceptar pagos.",
    open: "Abrir Mi Tienda",
  },

  apiauth: {
    title: "Autenticación",
    subtitle: "Por favor, inicie sesión con sus credenciales de Bull Bitcoin para continuar.",
    email: "Correo Electrónico",
    password: "Contraseña",
  },

  percentslider: {
    title: "Establecer Porcentaje",
    subtitle: "Seleccione un porcentaje entre 1 y 100. Este porcentaje representa la parte que debe ser convertida a fiat.",
    percentage: "Porcentaje",
  },

  posstore: {
    title: "Crear Tienda",
    subtitle: "Por favor, complete los detalles para crear una tienda.",
    storeName: "Nombre de la Tienda",
    storeOwnerEmail: "Correo Electrónico del Propietario de la Tienda",
    currency: "Moneda",
    language: "Idioma",
    bitcoinJungleUsername: "Nombre de Usuario de Bitcoin Jungle",
    create: "Crear Tienda",
  },

  pos: {
    title: "Puntos de Venta",
    subtitle: "Use esta página para ver los detalles de una tienda específica.",
    none: "No se encontraron tiendas.",

    storeId: "BTCPay Tienda",
    recipient: "Destinatario",

    explainerText: "El punto de venta (POS) es una función que le permite recoger pagos de sus clientes en Bitcoin. Cada vez que su cliente paga en Bitcoin, automáticamente convertirá su porcentaje deseado del pago en dólares o colones a su cuenta bancaria y entregará el saldo restante en Bitcoin a su billetera.",
    tryItOut: "¡Inténtalo!",
    customerPays: "El cliente paga",
    yourBusinessSplits: "Cantidad de Bitcoin/Fiat",

    qrCodeText: "Escanea para abrir tu Punto de Venta",
    qrCodeCopied: "El punto de venta ha sido copiado al portapapeles. Ahora puedes abrir este enlace en cualquier navegador web.",
  },

  paymentoptionmodal: {
    title: "Opciones de Pago",
    subtitle: "Por favor, seleccione su método de pago preferido.",
  },

  voucher: {
    title: "Crear Voucher",
    subtitle: "Crea un voucher de retiro desde tu saldo",
    fee: "Porcentaje de Comisión",
    available: "Disponible",
    createBtn: "Crear Voucher",
    scanTitle: "Escanear voucher de retiro",
    copiedText: "Voucher copiado al portapapeles",
  },

  referralCode: {
    title: "Código de Referencia",
    notMissionary: "Únete a la misión!",
    contactSupport: "Contacta soporte para aprender sobre nuestro programa de referencia",
    noCodes: "Aún no tienes códigos de referencia. Crea uno para comenzar a compartir.",
    createNew: "Crear",
    status: "Estado",
    approved: "Aprobado",
    pending: "Pendiente",
    copy: "Copiar",
    copied: "¡Copiado!",
    createTitle: "Crear Código de Referencia",
    createDescription: "Ingresa un código único que te gustaría usar como tu código de referencia. Una vez aprobado, puedes compartirlo con otros.",
    codePlaceholder: "Ingresa tu código de referencia",
    create: "Crear",
    errorFetching: "Error al obtener códigos de referencia",
    errorCreating: "Error al crear código de referencia",
    emptyCodeError: "El código de referencia no puede estar vacío",
  },
}

export default es